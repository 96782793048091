import { buildSrc } from '@lib/helpers'
import CustomLink from '../link'
import cx from 'classnames'

export default function Split({ data: { title, link, items } }) {
  const numOfItems = items?.length

  return (
    <section className="py-8 sm:py-13">
      <div className="flex items-center justify-between mb-8 sm:mb-13">
        <h3 className="text-xl sm:text-4xl sm:leading-7 text-black tracking-[1%] uppercase">
          {title}
        </h3>
        {link && (
          <CustomLink className="pill is-white is-outlined" link={link} />
        )}
      </div>
      <ul
        className={cx(
          'grid grid-cols-2 gap-x-4 gap-y-8',
          numOfItems === 3 && 'sm:grid-cols-3'
        )}
      >
        {items.map((item, index) => {
          const link = item.link?.[0]

          const content = (
            <>
              <div
                className={cx(
                  'mb-5 sm:mb-6 relative after:content-["*"] after:absolute after:inset-0 after:bg-black after:opacity-0 after:transition-opacity group-hover:after:opacity-10 overflow-hidden',
                  numOfItems === 3 && index === 0
                    ? 'aspect-w-7 aspect-h-4'
                    : 'aspect-w-3 aspect-h-4',
                  numOfItems === 3
                    ? 'sm:aspect-w-3 sm:aspect-h-4'
                    : 'sm:aspect-w-7 sm:aspect-h-8'
                )}
              >
                <img
                  src={buildSrc(item.image)}
                  alt={item.title}
                  className="w-full h-full object-cover"
                  width={3840}
                  height={5760}
                  sizes={
                    numOfItems === 3 ? '(min-width: 768px) 33vw, 50vw' : '50vw'
                  }
                />
              </div>
              <span className="pill is-white is-outlined w-max is-sm mb-3 sm:mb-4">
                {item.tag}
              </span>
              <h4 className="text-xs sm:text-sm leading-4 font-medium mb-4 sm:mb-5">
                {item.title}
              </h4>
              <p className="text-xs sm:text-sm leading-4 mb-4 sm:mb-5">
                {item.body}
              </p>
              {link && (
                <button className="pill is-white is-outlined hover:no-underline !border-black !border-[1px] !normal-case">
                  {link?.title || link?.page?.title}
                </button>
              )}
            </>
          )

          return (
            <li
              key={item.title}
              className={cx(
                numOfItems === 3 && index === 0 && 'col-span-2 sm:col-span-1'
              )}
            >
              {link ? (
                <CustomLink link={link} className="hover:no-underline group">
                  {content}
                </CustomLink>
              ) : (
                content
              )}
            </li>
          )
        })}
      </ul>
    </section>
  )
}
